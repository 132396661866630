<template>
  <div class="deposit">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header">
          <div class="card-title">Пополнение счета</div>
        </div>
        <div class="card-body">
          <div class="h6">Ваш ID {{ paymentCode }}</div>
          <div class="card border-success">
            <div class="card-body">
              <p>
                Вам присвоен ID {{ paymentCode }} После зачисления денежных средств на номинальный счет, Вам будет
                доступен полный функционал
                платформы и Вы сможете осуществлять инвестирование.
              </p>
              <p>Пополнять баланс на платформе можно только с банковского счета, принадлежащего Вам.</p>
              <p>
                Осуществляя платеж на номинальный счет Вы подтверждаете заключение
                <public-link to="/page/dogovor-po-sodeistviiu-v-investirovanii"
                             target="_blank"
                >договора по содействию в инвестировании
                </public-link>
                и
                <public-link to="/page/oferta-o-zakliuchenii-agentskogo-dogovora"
                             target="_blank">агентского договора
                </public-link>
                .
              </p>
            </div>
          </div>
          <p>
            Осуществлять инвестиции на платформе Лендли возможно лишь при наличии свободных средств на индивидуальном
            учетном счете.
          </p>
          <p>
            Пополнение средств осуществляется исключительно переводом средств с банковского счета, принадлежащего
            инвестору на номинальный счет
            Оператора платформы.
          </p>
        </div>
        <div v-if="lenderVerified" class="card-body block-requisites">
          <div class="h6">Данные счёта</div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <dl>
                <dt>Название</dt>
                <dd>ООО "ЛЕНДЛИ"</dd>
                <dt>ИНН</dt>
                <dd>{{requisites.payeeINN}}</dd>
                <dt>КПП</dt>
                <dd>{{requisites.kpp}}</dd>
                <dt>№ счета</dt>
                <dd>{{requisites.personalAcc}}</dd>
                <dt>Банк</dt>
                <dd>{{requisites.bankName}}</dd>
                <dt>БИК</dt>
                <dd>{{requisites.bic}}</dd>
                <dt>Кор. счет</dt>
                <dd>{{requisites.correspAcc}}</dd>
              </dl>
              <div class="card border-success m-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-3 col-sm-2">
                      <img src="@/assets/img/attention.png" alt="!">
                    </div>
                    <div class="col-9 col-sm-10">
                      <div>В поле "Назначение платежа" укажите:</div>
                      <div class="font-weight-bold mt-3">
                        {{getPurpose()}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 text-center">
              <qrcode-vue class="mt-4" size="250" level="L" :value="getQrCodeString()"></qrcode-vue>
            </div>
          </div>
        </div>
        <div v-if="lenderVerified === false" class="card border-info mx-3">
          <div class="card-body">
            Для возможности пополнения счета необходимо
            <router-link :to="{ name: 'authorization' }" class="font-weight-bold">пройти авторизацию</router-link>
          </div>
        </div>
        <div class="card-body">
          <div class="h6">Лимиты</div>
          <dl>
            <dt>Минимальная сумма</dt>
            <dd>
              <money :money="minInvestment" :decimal="true"/>
            </dd>
          </dl>
        </div>
        <div class="card-body">
          <div class="text-muted mb-2">Максимальная сумма</div>
          <dl>
            <dt class="font-weight-bold">Физические лица</dt>
            <dd>
              <money :money="maxInvestment" :decimal="true"/>
            </dd>
            <dt class="font-weight-bold">Физические лица, являющееся квалифицированными инвесторами</dt>
            <dd>Без ограничений</dd>
            <dt class="font-weight-bold">Индивидуальные предприниматели</dt>
            <dd>Без ограничений</dd>
            <dt class="font-weight-bold">Юридические лица</dt>
            <dd>Без ограничений</dd>
          </dl>
        </div>
        <div class="card-body">
          <div class="h6 text-success">Номинальный банковский счет</div>
          <p>
            Специальный счёт открытый юридическому лицу имеющему по закону право хранить отдельно средства третьих лиц.
            Средства находящиеся на данном
            типе счетов остаются собственностью инвестора и подлежат надзору и контролю со стороны финансового
            учреждения в котором открыт номинальный
            счет. Индивидуальный учетный счет является эквивалентным отражением принадлежащих инвестору средств на
            номинальном банковском счёте и
            предназначен только для учёта действий Инвестора на Платформе.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Money, PublicLink} from "@/components/common";
import {appConfig} from "@/configs";
import {Money as ApiMoney} from "@/utils/api-client";
import moment from "moment";
import QrcodeVue from 'qrcode.vue';

export default {
  name: "Deposit",
  components: {
    PublicLink,
    Money,
    QrcodeVue
  },
  data() {
    return {
      requisites: {
        name: 'ООО "ЛЕНДЛИ"',
        personalAcc: '40701810903500000173',
        bankName: 'ООО "Банк Точка"',
        bic: '044525104',
        correspAcc: '30101810745374525104',
        payeeINN: '7814733360',
        kpp: '781401001'
      }
    }
  },
  inject: ["profile"],
  computed: {
    paymentCode() {
      return this.profile.paymentCode;
    },
    lenderVerified() {
      return this.profile.lenderVerified;
    },
    identificationDate() {
      if (this.profile.identificationDate) {
        return moment(this.profile.identificationDate).format("DD.MM.YYYY");
      }
    },
    minInvestment() {
      return this.createMoney(appConfig.minInvestmentSecondary);
    },
    maxInvestment() {
      return this.createMoney(appConfig.maxInvestment);
    }
  },
  methods: {
    createMoney(amount) {
      return new ApiMoney(amount * 100, "RUB");
    },
    getPurpose(){
      return `Пополнение номинального счёта по договору от ${this.identificationDate }, ID ${this.paymentCode}, НДС не облагается.`
    },
    getQrCodeString() {
      return `ST00012|Name=${this.requisites.name}` +
          `|PersonalAcc=${this.requisites.personalAcc}` +
          `|BankName=${this.requisites.bankName}` +
          `|BIC=${this.requisites.bic}` +
          `|CorrespAcc=${this.requisites.correspAcc}` +
          `|PayeeINN=${this.requisites.payeeINN}` +
          `|KPP=${this.requisites.kpp}` +
          `|Purpose=${this.getPurpose()}`;
    }
  }
};
</script>

<style lang="scss">
.deposit {
  .card {
    .card-body {
      .h6 {
        margin-bottom: 1rem;
      }
    }
  }

  dl {
    dd {
      text-align: right;
    }
  }

  .block-requisites {
    dl {
      dt {
        flex: 0 0 35%;
        max-width: 35%;
      }

      dd {
        flex: 0 0 65%;
        max-width: 65%;
      }
    }

    @media (min-width: 576px) { // Bootstrap SM
      dl {
        dt {
          flex: 0 0 20%;
          max-width: 20%;
        }

        dd {
          flex: 0 0 80%;
          max-width: 80%;
        }
      }
    }
  }
}
</style>
